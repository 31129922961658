import React, { useEffect, useState } from "react";
import About from "../../components/about/about";
import Landing from "../../components/landing/landing";
import Projects from "../../components/projects/projects";
import Skills from "../../components/skills/skills";
import Timeline from "../../components/timeline/timeline";
import Recommendations from "../../components/recommendations/recommendations.component";
import { useLocation } from "react-router-dom";
import RootModal from "../../utils/modal/Root-modal";

export default function Home() {
  const location = useLocation();
  const [modalClosed, setModalClosed] = useState(true);

  useEffect(() => {
    console.log(location);
    const { hash } = location;
    if (hash) {
      const element = document.querySelector(hash);
      element?.scrollIntoView();
    }
  }, [location]);
  return (
    <div className="2xl:container">
      <RootModal modalClosed={modalClosed} setModalClosed={setModalClosed} />
      <Landing setModalClosed={setModalClosed} />
      <About />
      <Recommendations />
      <Projects />
      <Skills />
      <Timeline />
    </div>
  );
}
