import React from "react";
import aboutImage from "../../assets/images/about.png";
import aboutData from "../../data/about.json";

interface Social {
  alt: string;
  link: string;
  img: string;
}

export default function About() {
  return (
    <div
      id="about"
      className="py-16 px-5 sm:px-16 min-h-screen flex items-center relative overflow-hidden"
    >
      {/* Background image */}
      <div className="absolute inset-0 left-0 top-0 h-full opacity-30 sm:opacity-100 pointer-events-none">
        <div className="absolute inset-0 bg-gradient-to-l from-primary via-primary/95 to-transparent z-10" />
        <img
          src={aboutImage}
          alt="background"
          className="h-full w-auto object-contain object-left"
        />
      </div>

      {/* Main content container */}
      <div className="w-full max-w-6xl ml-auto relative z-20">
        <div className="flex flex-col lg:flex-row items-center justify-end gap-8">
          {/* Content */}
          <div className="w-full lg:w-1/2 bg-primary-light/30 backdrop-blur-md rounded-xl p-8 border border-secondary/20 relative">
            {/* Decorative corner accents */}
            <div className="absolute top-0 left-0 w-16 h-16 border-t-2 border-l-2 border-secondary rounded-tl-xl" />
            <div className="absolute top-0 right-0 w-16 h-16 border-t-2 border-r-2 border-ternary rounded-tr-xl" />
            <div className="absolute bottom-0 left-0 w-16 h-16 border-b-2 border-l-2 border-ternary rounded-bl-xl" />
            <div className="absolute bottom-0 right-0 w-16 h-16 border-b-2 border-r-2 border-secondary rounded-br-xl" />
            
            {/* Content */}
            <div className="relative space-y-6">
              {/* Title */}
              <div className="space-y-2">
                <h2 className="heading leading-tight">
                  {aboutData.title[0]}
                </h2>
                <h3 className="text-xl text-ternary">{aboutData.subtitle}</h3>
              </div>

              {/* Description */}
              <div className="space-y-4">
                {aboutData.description.map((para, index) => (
                  <p key={index} className="text-light/90 leading-relaxed">
                    {para}
                  </p>
                ))}
              </div>

              {/* Social links */}
              <div className="pt-4">
                <ul className="flex gap-6">
                  {aboutData.socials.map((social: Social, index) => (
                    <li key={index} className="group">
                      <a
                        href={social.link}
                        rel="noreferrer"
                        target="_blank"
                        className="block transition-transform duration-300 group-hover:scale-110"
                      >
                        <img
                          src={social.img}
                          alt={social.alt}
                          className="h-10 w-10 transition-all duration-300 group-hover:drop-shadow-[0_5px_10px_rgba(92,157,204,0.7)]"
                        />
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}