import React, { useState } from 'react';
import { Send, CheckCircle2, Loader2 } from 'lucide-react';

export default function ContactForm() {
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleMessage = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch("https://formsubmit.co/ajax/arvndvv@gmail.com", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          ...formData,
          site: "arvnd.in",
          _template: "table",
        }),
      });
      if (response.ok) setShowSuccess(true);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full h-full p-3 sm:p-5">
      {showSuccess ? (
        <div className="absolute inset-0 bg-gradient-to-br from-secondary/10 to-ternary/10 backdrop-blur-sm z-10 flex flex-col justify-center items-center text-center p-4 sm:p-6">
          <CheckCircle2 className="w-16 h-16 sm:w-24 sm:h-24 text-secondary mb-4 sm:mb-6 animate-bounce" />
          <h1 className="text-3xl sm:text-5xl font-bold text-primary mb-3 sm:mb-4">
            Thank You
          </h1>
          <p className="text-subtle max-w-md mb-6 sm:mb-8 text-sm sm:text-base">
            Your message has been successfully delivered. I'll get back to you soon!
          </p>
          <button 
            onClick={() => {
              setShowSuccess(false);
              setFormData({ name: '', email: '', message: '' });
            }}
            className="px-4 py-2 sm:px-6 sm:py-3 text-sm sm:text-base bg-secondary text-white rounded-lg hover:bg-secondary-light transition-colors duration-300 flex items-center gap-2"
          >
            Send Another Message
          </button>
        </div>
      ) : (
        <form 
          className="grid gap-4 sm:gap-6 bg-primary-light border border-secondary/20 rounded-xl p-4 sm:p-6 shadow-lg"
          onSubmit={handleMessage}
        >
          <div className="grid md:grid-cols-2 gap-4 sm:gap-6">
            <div>
              <label 
                htmlFor="name" 
                className="block mb-1 sm:mb-2 text-xs sm:text-sm font-medium text-subtle"
              >
                Full Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="w-full px-3 py-2 sm:px-4 sm:py-3 text-sm sm:text-base bg-primary border border-secondary/30 rounded-lg text-light focus:outline-none focus:ring-2 focus:ring-secondary/50 transition-all duration-300"
                placeholder="Your Name"
                required
              />
            </div>
            <div>
              <label 
                htmlFor="email" 
                className="block mb-1 sm:mb-2 text-xs sm:text-sm font-medium text-subtle"
              >
                Email Address
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full px-3 py-2 sm:px-4 sm:py-3 text-sm sm:text-base bg-primary border border-secondary/30 rounded-lg text-light focus:outline-none focus:ring-2 focus:ring-secondary/50 transition-all duration-300"
                placeholder="you@example.com"
                required
              />
            </div>
          </div>
          <div>
            <label 
              htmlFor="message" 
              className="block mb-1 sm:mb-2 text-xs sm:text-sm font-medium text-subtle"
            >
              Your Message
            </label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleInputChange}
              rows={4}
              className="w-full px-3 py-2 sm:px-4 sm:py-3 text-sm sm:text-base bg-primary border border-secondary/30 rounded-lg text-light focus:outline-none focus:ring-2 focus:ring-secondary/50 transition-all duration-300 resize-none"
              placeholder="What would you like to discuss?"
              required
            ></textarea>
          </div>
          <button
            type="submit"
            disabled={loading}
            className="w-full py-2 sm:py-3 text-sm sm:text-base bg-secondary text-white rounded-lg hover:bg-secondary-light transition-colors duration-300 flex justify-center items-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {loading ? (
              <>
                <Loader2 className="w-4 h-4 sm:w-5 sm:h-5 animate-spin" />
                Sending...
              </>
            ) : (
              <>
                <Send className="w-4 h-4 sm:w-5 sm:h-5" />
                Send Message
              </>
            )}
          </button>
        </form>
      )}
    </div>
  );
}