import React, { useEffect } from 'react';
import { X } from 'lucide-react';

interface ModalProps {
  url?: string;
  title?: string;
  closed?: boolean;
  component?: React.ReactNode;
  setClosed: (closed: boolean) => void;
}

function Modal({ url, title, closed, setClosed, component }: ModalProps) {
  useEffect(() => {
    document.documentElement.style.overflow = closed ? 'auto' : 'hidden';
    document.body.style.overflow = closed ? 'auto' : 'hidden';
    return () => {
      document.documentElement.style.overflow = 'auto';
      document.body.style.overflow = 'auto';
    };
  }, [closed]);

  return (
    <div 
      className={`
        fixed inset-0 z-50 flex items-start sm:items-center justify-center
        bg-black/30 backdrop-blur-sm overflow-y-auto
        transition-all duration-300 pt-4 sm:pt-0
        ${closed ? 'opacity-0 pointer-events-none' : 'opacity-100'}
      `}
      onClick={() => setClosed(true)}
    >
      <div 
        className={`
          relative w-full max-w-2xl mx-4 my-4 sm:my-8
          bg-primary-light border border-secondary/20 
          rounded-xl shadow-2xl
          transition-all duration-300 transform
          ${closed ? 'scale-90 opacity-0' : 'scale-100 opacity-100'}
        `}
        onClick={(e) => e.stopPropagation()}
      >
        {/* Header */}
        <div className="flex justify-between items-center p-4 sm:p-6 pb-0">
          <h2 className="text-xl sm:text-2xl font-semibold text-secondary">{title}</h2>
          <button
            onClick={() => setClosed(true)}
            className="text-subtle hover:text-secondary transition-colors"
            aria-label="Close modal"
          >
            <X className="w-5 h-5 sm:w-6 sm:h-6" />
          </button>
        </div>

        {/* Content */}
        <div className="p-4 sm:p-6">
          {url ? (
            <iframe 
              src={url} 
              className="w-full h-[70vh] rounded-lg bg-white" 
              title={title}
            />
          ) : (
            component
          )}
        </div>
      </div>
    </div>
  );
}

export default Modal;