import React from 'react';
import { Code2, Braces, FileType, Component, Layout, PaintBucket, GitBranch, Beaker, Zap, Database, Figma, Users, RocketIcon, Binary, HeartHandshake, School, Trophy } from 'lucide-react';
import skillsData from '../../data/skills.json';

interface Skill {
  name: string;
  icon: string;
  proficiency: 'expert' | 'intermediate';
  description?: string;
}

interface Category {
  category: string;
  skills: Skill[];
}

interface Methodology {
  name: string;
  description: string;
}

interface SoftSkill {
  name: string;
  highlights: string[];
}

interface SkillsData {
  technical: Category[];
  methodologies: Methodology[];
  soft_skills: SoftSkill[];
}

const iconMap: Record<string, React.ComponentType<any>> = {
  "HTML": FileType,
  "CSS": Layout,
  "JavaScript": Braces,
  "React JS": Component,
  "Angular": Code2,
  "Next.js": RocketIcon,
  "SASS": PaintBucket,
  "Tailwind": PaintBucket,
  "Git": GitBranch,
  "Jest": Beaker,
  "Vite": Zap,
  "Firebase": Database,
  "Figma": Figma
};

export default function Skills() {
  const skills = skillsData as SkillsData;

  return (
    <div className='py-16 px-5 sm:px-16 min-h-screen relative'>
      <h2 className="heading mb-16">
        Skills & <br />
        Expertise
      </h2>

      <div className="max-w-6xl mx-auto space-y-16">
        {/* Technical Skills */}
        <section className="space-y-8">
          <h3 className="text-2xl text-secondary font-semibold">Technical Skills</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {skills.technical.map((category, idx) => (
              <div 
                key={idx}
                className="bg-primary-light/30 backdrop-blur-md rounded-xl p-6 border border-secondary/20"
              >
                <h4 className="text-xl mb-4 text-ternary">{category.category}</h4>
                <div className="space-y-4">
                  {category.skills.map((skill, skillIdx) => (
                    <div key={skillIdx} className="flex items-center gap-3">
                      {iconMap[skill.name] && React.createElement(iconMap[skill.name], {
                        className: `w-5 h-5 ${skill.proficiency === 'expert' ? 'text-secondary' : 'text-subtle'}`
                      })}
                      <div>
                        <p className="text-light">{skill.name}</p>
                        {skill.description && (
                          <p className="text-xs text-subtle">{skill.description}</p>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* Methodologies */}
        <section className="space-y-8">
          <h3 className="text-2xl text-secondary font-semibold">Methodologies & Processes</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {skills.methodologies.map((method, idx) => (
              <div 
                key={idx}
                className="bg-primary-light/30 backdrop-blur-md rounded-xl p-6 border border-secondary/20"
              >
                <h4 className="text-xl mb-2 text-ternary flex items-center gap-2">
                  <Binary className="w-5 h-5" />
                  {method.name}
                </h4>
                <p className="text-subtle text-sm">{method.description}</p>
              </div>
            ))}
          </div>
        </section>

        {/* Soft Skills */}
        <section className="space-y-8">
          <h3 className="text-2xl text-secondary font-semibold">Soft Skills</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {skills.soft_skills.map((skill, idx) => (
              <div 
                key={idx}
                className="bg-primary-light/30 backdrop-blur-md rounded-xl p-6 border border-secondary/20"
              >
                <h4 className="text-xl mb-3 text-ternary flex items-center gap-2">
                  {idx === 0 ? <HeartHandshake className="w-5 h-5" /> :
                   idx === 1 ? <Users className="w-5 h-5" /> :
                   idx === 2 ? <School className="w-5 h-5" /> :
                   <Trophy className="w-5 h-5" />}
                  {skill.name}
                </h4>
                <ul className="space-y-2">
                  {skill.highlights.map((highlight, hIdx) => (
                    <li key={hIdx} className="text-subtle text-sm flex items-center gap-2">
                      <div className="w-1 h-1 bg-secondary rounded-full" />
                      {highlight}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </section>
      </div>
    </div>
  );
}