import React from 'react';
import Modal from "./modal";
import ContactForm from '../../components/contact-form/contact_form';

export default function RootModal({ modalClosed, setModalClosed }:{modalClosed:boolean,setModalClosed:any}) {
  if (modalClosed) return null;
  
  return (
    <div className="fixed inset-0 z-[100]">
      <Modal
        title="Contact Me"
        closed={modalClosed}
        setClosed={setModalClosed}
        component={<ContactForm />}
      />
    </div>
  );
}