import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

interface ExpandableTextProps {
  text: string;
  maxLength?: number;
 }
 
 const ExpandableText: React.FC<ExpandableTextProps> = ({ text, maxLength = 150 }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const needsExpansion = text.length > maxLength;
 
  return (
    <div className="relative">
      <p className={`${!isExpanded && needsExpansion ? 'line-clamp-3' : ''} transition-all duration-300`}>
        {text}
      </p>
      {needsExpansion && (
        <button
          onClick={(e) => {e.preventDefault();e.stopPropagation();setIsExpanded(!isExpanded)}}
          className="text-secondary hover:text-secondary-light text-sm mt-2 flex items-center gap-1 transition-colors duration-300"
        >
          {isExpanded ? (
            <>
              <ChevronUp className="w-4 h-4" />
              Show Less
            </>
          ) : (
            <>
              <ChevronDown className="w-4 h-4" />
              Read More
            </>
          )}
        </button>
      )}
    </div>
  );
 };
 
 export default ExpandableText;