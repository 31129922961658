import React from "react";
import timelines from "../../data/timeline.json";
import { ReactComponent as Cloud } from "../../assets/images/cloud.svg";
import { ReactComponent as Rocket } from "../../assets/images/rocket.svg";
import { ExternalLink, ChevronRight } from "lucide-react";
import "./timeline.scss";
import { matchAndReplace } from "../../helpers/helpers";

function Timeline() {
  return (
    <div className="timeline-section pt-5 min-h-screen flex relative flex-col gap-5 overflow-hidden pb-[15em] sm:pb-[20em] px-5 sm:!px-16 ">
      <h2 className="heading">
        Into the <br />
        Time <s className="opacity-50">travel</s> line
      </h2>
      <div className="timeline__container mt-16 sm:px-10 max-w-[1000px] mx-auto relative">
        <Rocket className="rocket absolute -right-[16px] sm:right-[20px] md:right-[22px] lg:right-[15px] xl:right-[12px] z-10 -top-[75px] sm:-top-[7%] md:-top-[120px] w-[12%] sm:w-[6%] rotate-180" />
        <span>
          {timelines.map((timeline, i) => {
            return (
              <div
                className={`timeline__item timeline__item--${(i+1)%2 === 0 ? 'even' : 'odd' } [&:last-child]:after:w-[20%] [&:last-child]:after:h-full [&:last-child]:after:border-dashed [&:last-child]:after:absolute [&:last-child]:after:left-[30%]  [&:last-child]:after:bottom-[calc(-100%_-_15px)]  sm:[&:last-child]:after:!bottom-[calc(-100%_+_20px)] lg:[&:last-child]:after:!bottom-[calc(-92.9%)]  [&:last-child]:after:border-secondary [&:last-child]:after:border-4 [&:last-child]:after:border-r-0 [&:last-child]:after:border-b-0 [&:last-child]:after:rounded-tl-[200px]`}
                key={i}
              >
                <div className="timeline__item__year">{timeline.year}</div>
                <div className="group timeline__item__content relative overflow-hidden flex flex-col w-full sm:w-[80%] lg:w-[40%] rounded-xl p-6">
                  {/* Background with blur effect */}
                  <div className="absolute inset-0 bg-primary-light/30 backdrop-blur-md transition-all duration-300 group-hover:bg-primary-light/40" />
                  
                  {/* Decorative corners */}
                  <div className="absolute top-0 left-0 w-12 h-12 border-t-2 border-l-2 border-secondary/20 rounded-tl-xl group-hover:border-secondary/40 transition-all duration-300" />
                  <div className="absolute top-0 right-0 w-12 h-12 border-t-2 border-r-2 border-ternary/20 rounded-tr-xl group-hover:border-ternary/40 transition-all duration-300" />
                  <div className="absolute bottom-0 left-0 w-12 h-12 border-b-2 border-l-2 border-ternary/20 rounded-bl-xl group-hover:border-ternary/40 transition-all duration-300" />
                  <div className="absolute bottom-0 right-0 w-12 h-12 border-b-2 border-r-2 border-secondary/20 rounded-br-xl group-hover:border-secondary/40 transition-all duration-300" />

                  {/* Content */}
                  <div className="relative z-10 flex flex-col gap-4">
                    {/* Header */}
                    <div className="flex justify-between items-start">
                      <div className="space-y-2">
                        <h4 className="text-xl text-secondary font-medium group-hover:text-secondary/80 transition-colors duration-300">
                          {timeline.title}
                        </h4>
                        <a
                          href={timeline.company.url || "#"}
                          rel="noreferrer"
                          target="_blank"
                          className="flex items-center gap-1 text-ternary hover:text-ternary-light transition-colors duration-200"
                        >
                          {timeline.company.name}
                          {timeline.company.url !== "#" && (
                            <ExternalLink className="w-4 h-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                          )}
                        </a>
                      </div>
                      <ChevronRight className="w-5 h-5 text-subtle group-hover:text-secondary group-hover:translate-x-1 transition-all duration-300" />
                    </div>

                    {/* Description */}
                    <div className="text-light/80">
                      {matchAndReplace(
                        timeline.short_desc,
                        "p",
                        "timeline__item__description"
                      )}
                    </div>

                    {/* Points */}
                    {timeline.points && (
                      <ul className="text-sm pl-4 list-disc space-y-1 text-light/80">
                        {timeline.points.map((point, index) => (
                          <li key={index} className="my-1">{point}</li>
                        ))}
                      </ul>
                    )}

                    {/* Stack/Batch info */}
                    <p className="text-base font-normal pt-2">
                      <span className="text-secondary font-normal">
                        {timeline.batch ? "Batch" : "Stack"}:{" "}
                      </span>
                      <span className="text-light/80">
                        {timeline.batch
                          ? timeline.batch
                          : timeline.stack.join(", ")}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </span>
      </div>
      <Cloud className="absolute w-[200%] -left-[50%] -bottom-[6%]" />
      <div className="footer absolute bottom-0 w-full left-0 text-center text-primary font-semibold p-3 flex justify-center">
        <a className="w-fit" target='_blank' href='https://webarv.com' rel="noreferrer">
          <div
            title="webarv"
            className="relative w-fit flex opacity-80 hover:opacity-100 cursor-pointer gap-1 items-center group"
          >
            <span className="text-gray-900">developed by</span>
            <img src="/images/webarv.svg" alt="webarv" className="w-6 h-[25px]" />
            <span className="-right-[6ch] text-[#00a99d] absolute opacity-0 group-hover:opacity-100 transform translate-x-[-10px] group-hover:translate-x-0 transition-all duration-300">
              webarv
            </span>
          </div>
        </a>
      </div>
    </div>
  );
}

export default Timeline;