import React, { useState } from 'react';
import { ExternalLink, Maximize2, ChevronRight } from 'lucide-react';
import Modal from '../../utils/modal/modal';
import projectsData from '../../data/projects.json';
import ExpandableText from '../../utils/others/ExpandableText';

interface Project {
  title: string;
  stack: string[];
  description: string;
  url: string;
}

export default function Projects() {
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [modalClosed, setModalClosed] = useState<boolean>(true);

  const handleProjectClick = (project: Project) => {
    setSelectedProject(project);
    setModalClosed(false);
  };

  const handleDemoClick = (e: React.MouseEvent, project: Project) => {
    e.stopPropagation();
    setSelectedProject(project);
    setModalClosed(false);
  };

  return (
    <div id="projects" className="py-16 px-5 sm:px-16 flex relative flex-col gap-8">
      <h2 className="heading">
        Grab a Coffee & <br />
        See the projects
      </h2>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 max-w-6xl mx-auto">
        {projectsData.map((project, index) => (
          <div 
            key={index}
            onClick={() => handleProjectClick(project)}
            className="group relative bg-primary-light backdrop-blur-md rounded-xl p-6 border border-secondary/20 
                     hover:border-secondary/40 transition-all duration-300 cursor-pointer overflow-hidden"
          >
            {/* Background gradient effect */}
            <div className="absolute inset-0 bg-gradient-to-br from-secondary/5 to-ternary/5 
                          group-hover:from-secondary/10 group-hover:to-ternary/10 transition-all duration-300" />
            
            {/* Corner decorations */}
            <div className="absolute top-0 right-0 w-16 h-16 border-t border-r border-secondary/20 rounded-tr-xl
                          group-hover:border-secondary/40 transition-all duration-300" />
            <div className="absolute bottom-0 left-0 w-16 h-16 border-b border-l border-ternary/20 rounded-bl-xl
                          group-hover:border-ternary/40 transition-all duration-300" />

            <div className="relative z-10">
              {/* Project Title and Stack */}
              <div className="flex justify-between items-start mb-4">
                <div>
                  <h3 className="text-xl font-semibold text-secondary mb-2">{project.title}</h3>
                  <div className="flex flex-wrap gap-2">
                    {project.stack.map((tech, idx) => (
                      <span 
                        key={idx} 
                        className="text-xs px-2 py-1 rounded-full bg-secondary/10 text-subtle"
                      >
                        {tech}
                      </span>
                    ))}
                  </div>
                </div>
                <ChevronRight className="w-5 h-5 text-subtle group-hover:text-secondary 
                                      transform group-hover:translate-x-1 transition-all duration-300" />
              </div>

              {/* Project Description */}
              <p className="text-subtle mb-6 "><ExpandableText text={project.description} maxLength={150} /></p>

              {/* Action Buttons */}
              <div className="flex items-center gap-4 mt-auto">
                <button
                  onClick={(e) => handleDemoClick(e, project)}
                  className="flex items-center gap-2 text-secondary hover:text-secondary/80 transition-colors duration-200"
                >
                  <Maximize2 className="w-4 h-4" />
                  <span>Quick Demo</span>
                </button>
                <a
                  href={project.url}
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center gap-2 text-ternary hover:text-ternary-light transition-colors duration-200"
                  onClick={(e) => e.stopPropagation()}
                >
                  <ExternalLink className="w-4 h-4" />
                  <span>Live Demo</span>
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>

      {selectedProject && (
        <Modal
          url={selectedProject.url}
          title={selectedProject.title}
          closed={modalClosed}
          setClosed={setModalClosed}
        />
      )}
    </div>
  );
}