import React, { useState, useEffect, useCallback } from 'react';
import { 
  ChevronLeft, 
  ChevronRight, 
  Pause, 
  Play, 
  ExternalLink, 
  Quote, 
  Star 
} from 'lucide-react';
import recommendationsData from "../../data/recommendations.json";
import ExpandableText from '../../utils/others/ExpandableText';

interface Recommendation {
  msg: string;
  name: string;
  designation: string;
  company: {
    name: string;
    link: string;
  };
}

export default function Recommendations() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const SLIDE_INTERVAL = 4000;

  const recommendations = recommendationsData.data;

  const nextSlide = useCallback(() => {
    setCurrentIndex((prev) => (prev + 1) % recommendations.length);
  }, [recommendations.length]);

  const prevSlide = () => {
    setCurrentIndex((prev) =>
      prev === 0 ? recommendations.length - 1 : prev - 1
    );
  };

  useEffect(() => {
    let slideTimer: NodeJS.Timeout;
    if (isPlaying) {
      slideTimer = setInterval(nextSlide, SLIDE_INTERVAL);
    }
    return () => {
      if (slideTimer) {
        clearInterval(slideTimer);
      }
    };
  }, [isPlaying, nextSlide]);

  const togglePlayPause = () => {
    setIsPlaying(prev => !prev);
  };

  const renderStars = (count = 5) => {
    return Array.from({ length: count }, (_, i) => (
      <Star 
        key={i} 
        className="w-5 h-5 text-secondary fill-secondary/30" 
      />
    ));
  };

  const currentRecommendation: Recommendation = recommendations[currentIndex];

  return (
    <div className="py-16 px-5 sm:px-16 flex relative flex-col gap-8">
      <h2 className="heading">{recommendationsData.title}</h2>

      <div className="relative w-full max-w-4xl mx-auto">
        {/* Background Gradient */}
        <div className="absolute inset-0 bg-gradient-to-br from-secondary/10 to-ternary/10 rounded-2xl blur-xl" />

        {/* Main Testimonial Card */}
        <div className="relative bg-primary-light border border-secondary/20 backdrop-blur-md rounded-2xl p-8 sm:p-12 overflow-hidden shadow-lg">
          {/* Decorative Quote Icons */}
          <Quote className="absolute top-4 left-4 w-12 h-12 text-secondary/20" />
          <Quote className="absolute bottom-4 right-4 w-12 h-12 text-secondary/20 rotate-180" />

          {/* Navigation Buttons */}
          <div className="absolute inset-y-0 left-0 right-0 flex items-center justify-between z-10 pointer-events-none">
            <button
              onClick={prevSlide}
              className="pointer-events-auto bg-secondary/20 hover:bg-secondary/30 p-2 rounded-full ml-2 sm:ml-0 sm:-ml-12 transition-colors duration-200 hover:scale-110 active:scale-95"
            >
              <ChevronLeft className="w-6 h-6 text-white" />
            </button>
            <button
              onClick={nextSlide}
              className="pointer-events-auto bg-secondary/20 hover:bg-secondary/30 p-2 rounded-full mr-2 sm:mr-0 sm:-mr-12 transition-colors duration-200 hover:scale-110 active:scale-95"
            >
              <ChevronRight className="w-6 h-6 text-white" />
            </button>
          </div>

          {/* Testimonial Content */}
          <div className="relative z-20 flex flex-col items-center text-center">
            {/* Stars */}
            <div className="flex mb-6">
              {renderStars()}
            </div>

            {/* Testimonial Message */}
            <p className="text-light text-lg sm:text-xl leading-relaxed mb-8 max-w-3xl italic relative">
              <ExpandableText text={currentRecommendation.msg}></ExpandableText>
            </p>

            {/* Author Details */}
            <div className="mt-6 flex flex-col items-center">
              <h4 className="text-xl sm:text-2xl font-semibold text-secondary mb-2">
                {currentRecommendation.name}
              </h4>
              <p className="text-subtle mb-1">
                {currentRecommendation.designation}
              </p>
              <a
                href={currentRecommendation.company.link}
                target="_blank"
                rel="noreferrer"
                className="text-ternary hover:text-ternary-light transition-colors duration-200 flex items-center gap-1"
              >
                {currentRecommendation.company.name}
                <ExternalLink className="w-4 h-4" />
              </a>
            </div>
          </div>

          {/* Slider Controls */}
          <div className="absolute bottom-4 left-0 right-0 flex justify-center items-center gap-4">
            {/* Slide Indicators */}
            <div className="flex items-center gap-2">
              {recommendations.map((_, index) => (
                <button
                  key={index}
                  onClick={() => setCurrentIndex(index)}
                  className={`
                    w-2 h-2 rounded-full transition-all duration-300
                    ${index === currentIndex 
                      ? 'bg-secondary w-4' 
                      : 'bg-subtle hover:bg-secondary/50'}
                  `}
                />
              ))}
            </div>

            {/* Play/Pause Toggle */}
            <button
              onClick={togglePlayPause}
              className="text-subtle hover:text-secondary transition-colors duration-200"
              aria-label={isPlaying ? 'Pause' : 'Play'}
            >
              {isPlaying ? (
                <Pause className="w-5 h-5" />
              ) : (
                <Play className="w-5 h-5" />
              )}
            </button>

            {/* LinkedIn Link */}
            <a
              href="https://www.linkedin.com/in/arvndvv/details/recommendations/"
              rel="noreferrer"
              target="_blank"
              className="flex items-center gap-2 text-subtle hover:text-secondary transition-colors duration-200 group"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                className="fill-current group-hover:text-secondary transition-colors duration-200"
              >
                <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.784 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
              </svg>
              <ExternalLink className="w-4 h-4" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}