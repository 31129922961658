import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import landingImage from "../../assets/images/arv.png";
import landingData from "../../data/landing.json";
import { ReactComponent as ChatIcon } from "../../assets/icons/chat.svg";
import { Menu, Mail, ChevronDown } from 'lucide-react';
// import Modal from "../../utils/modal/modal";
// import ContactForm from "../contact-form/contact_form";
import Chat from "../chat/chat";
import Nav from "../nav/nav";
import Button from "../../utils/button/button";

const MOBILE_WIDTH = 768;
export default function Landing({setModalClosed}:{setModalClosed:any}) {
  const [chatOpen, setChatOpen] = useState(false);
  const [clearChat, setClearChat] = useState(false);
  // const [showHelp, setShowHelp] = useState(false);
  // const [modalClosed, setModalClosed] = useState(true);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth<MOBILE_WIDTH);

  const handleChatOpen = (val: boolean) => {
    if (val) {
      setClearChat(val);
      setTimeout(() => setChatOpen(val), 100);
    } else {
      setChatOpen(val);
      setTimeout(() => setClearChat(val), 500);
    }
  };

  useEffect(() => {
    let showedGuide = false;
    const handleScroll = () => {
      const isMobile = window.innerWidth < 768;
      if (!showedGuide && window.scrollY > 100) {
        // setShowHelp(true);
        showedGuide = true;
        setTimeout(() => {
          // setShowHelp(false);
          if (isMobile) window.removeEventListener("scroll", handleScroll);
        }, 5000);
      }
      if (!isMobile && window.scrollY > document.body.scrollHeight - 1800) {
        handleChatOpen(true);
        window.removeEventListener("scroll", handleScroll);
      }
    };
    window.addEventListener("resize",()=>{
      setIsMobileView(window.innerWidth<MOBILE_WIDTH);
    })
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToNext = () => {
    const nextSection = document.getElementById('about');
    nextSection?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="min-h-screen flex flex-col relative overflow-hidden">
      {/* Mobile Menu */}
      {mobileMenuOpen && (
        <div className="fixed inset-0 bg-primary z-50 flex flex-col items-center justify-center space-y-6">
          <button 
            onClick={() => setMobileMenuOpen(false)} 
            className="absolute top-5 right-5 text-white"
          >
            <Menu className="w-8 h-8" />
          </button>
          <a href="#about" onClick={() => setMobileMenuOpen(false)} className="text-white text-2xl">About me</a>
          <a href="#projects" onClick={() => setMobileMenuOpen(false)} className="text-white text-2xl">Projects</a>
          <button 
            onClick={() => {
              setModalClosed(false);
              setMobileMenuOpen(false);
            }} 
            className="text-white text-2xl"
          >
            Get in touch
          </button>
        </div>
      )}

      {/* Animated background elements */}
      <div className="absolute inset-0 bg-primary">
        <motion.div 
          className="absolute w-[500px] h-[500px] rounded-full bg-secondary/5 blur-3xl"
          animate={{
            x: ["-25%", "25%"],
            y: ["-25%", "25%"],
          }}
          transition={{
            duration: 15,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "easeInOut",
          }}
        />
        <motion.div 
          className="absolute right-0 bottom-0 w-[500px] h-[500px] rounded-full bg-ternary/5 blur-3xl"
          animate={{
            x: ["25%", "-25%"],
            y: ["25%", "-25%"],
          }}
          transition={{
            duration: 20,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "easeInOut",
          }}
        />
      </div>
      
      {/* Content */}
      <div className="relative z-10 py-8 px-5 sm:px-16 flex flex-col flex-grow">
        {/* Mobile Nav */}
        <div className="flex justify-between items-center md:hidden">
          <img src="/fav.svg" alt="logo" className="w-10 h-10" />
          <button onClick={() => setMobileMenuOpen(true)}>
            <Menu className="w-6 h-6 text-white" />
          </button>
        </div>

        {/* <Modal
          title="Contact Me"
          closed={modalClosed}
          setClosed={setModalClosed}
          component={<ContactForm />}
        /> */}

        {isMobileView ? "" :<Nav handleChatOpen={() => setModalClosed(false)} />}

        <div className="flex-grow grid lg:grid-cols-2 md:gap-12 items-center sm:mt-16">
          {/* Left side content */}
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="space-y-8 text-center lg:text-left order-2 lg:order-1"
          >
            {/* Intro text */}
            <div className="space-y-3">
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.2, duration: 0.8 }}
                className="text-lg sm:text-xl"
              >
                Hi, <span className="text-secondary relative group">
                  I am
                  <motion.span 
                    className="absolute bottom-0 left-0 w-full h-0.5 bg-secondary"
                    initial={{ scaleX: 0 }}
                    whileHover={{ scaleX: 1 }}
                    transition={{ duration: 0.3 }}
                  />
                </span>
              </motion.div>
              
              <motion.h1
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4, duration: 0.8 }}
                className="text-4xl sm:text-5xl font-bold text-light relative group"
              >
                ARAVIND V V
                <motion.div 
                  className="absolute inset-0 bg-secondary/20 rounded-lg -z-10"
                  initial={{ opacity: 0, scale: 0.8 }}
                  whileHover={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.3 }}
                />
              </motion.h1>

              <motion.h2
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.6, duration: 0.8 }}
                className="text-2xl text-secondary"
              >
                {landingData.designation}
              </motion.h2>
            </div>

            {/* Description */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.8, duration: 0.8 }}
              className="space-y-4 max-w-2xl mx-auto lg:mx-0"
            >
              {landingData.description.map((para, index) => (
                <p key={index} className="text-subtle sm:text-lg">
                  {para}
                </p>
              ))}
            </motion.div>

            {/* Action buttons */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 1, duration: 0.8 }}
              className="flex flex-wrap items-center justify-center lg:justify-start gap-4"
            >
              <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                <Button
                  py="1"
                  px="6"
                  fsize="xl"
                  onClick={() => setModalClosed(false)}
                >
                  Hire Me
                </Button>
              </motion.div>

              <motion.a
  whileHover={{ scale: 1.05 }}
  whileTap={{ scale: 0.95 }}
  href="mailto:arvndvv@gmail.com"
  className="group rounded-xl inline-flex items-center gap-2 px-6 py-1 text-secondary border-2 border-secondary bg-translucent backdrop-blur-sm hover:bg-secondary hover:text-white transition-all duration-300"
>
  <Mail className="w-5 h-5 transition-transform group-hover:scale-110" />
  <span>Send a mail</span>
</motion.a>
            </motion.div>
          </motion.div>

          {/* Right side image */}
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1 }}
            className="relative mx-auto group order-1 lg:order-2 mb-8 lg:mb-0"
          >
            <motion.img
              src={landingImage}
              alt="Profile"
              className="w-[180px] sm:w-[250px] md:w-[380px] object-contain relative z-10 filter grayscale"
              animate={{ y: [-10, 10, -10] }}
              transition={{ duration: 4, repeat: Infinity, ease: "easeInOut" }}
            />
            <motion.div
              className="absolute inset-0 bg-gradient-to-r from-secondary/20 to-ternary/20 rounded-full blur-3xl -z-10"
              initial={{ opacity: 0 }}
              animate={{ opacity: [0.3, 0.6, 0.3] }}
              transition={{ duration: 3, repeat: Infinity }}
            />
          </motion.div>
        </div>

        {/* Scroll indicator */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 1.2, duration: 0.8 }}
          className="flex flex-col items-center gap-0 cursor-pointer mt-10 sm:mt-0 sm:top-[90%] sm:left-1/2 sm:absolute"
          onClick={scrollToNext}
        >
          <span className="text-subtle text-sm">Scroll to explore</span>
          <motion.div
            animate={{ y: [0, 5, 0] }}
            transition={{ duration: 2, repeat: Infinity }}
          >
            <ChevronDown className="w-6 h-6 text-secondary" />
          </motion.div>
        </motion.div>
      </div>

        {/* Chat container */}
        <div className="fixed bottom-0 right-0 z-30">
        <div className="relative">
          {/*<div className={`
            guide-chat__text fixed bottom-28 right-10 sm:right-16
            rounded-xl py-5 px-3 pr-7 whitespace-nowrap z-10
            bg-white/20 backdrop-blur-md border-2 border-white
            text-white text-md shadow-lg tracking-wider
            transition-all duration-500 ease-in-out
            ${showHelp ? 'opacity-100' : 'opacity-0 pointer-events-none -bottom-10'}
          `}>
            Send a quick message to me.
            <button
              className="absolute top-1 right-1 w-5 h-5 bg-red-600 rounded-full text-sm flex items-center justify-center hover:bg-red-700"
              onClick={() => setShowHelp(false)}
            >
              ×
            </button>
          </div>*/}

          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={() => handleChatOpen(true)}
            className={`
              fixed right-10 bottom-10 w-[75px] h-[75px] bg-primary rounded-full z-30
              flex items-center justify-center transition-all duration-300
              hover:shadow-xl hover:shadow-secondary group
              ${chatOpen ? 'opacity-0 pointer-events-none' : 'delay-300 opacity-100'}
            `}
          >
            <motion.div 
              className="absolute inset-0 rounded-full shadow-lg shadow-secondary"
              animate={{ rotate: 360 }}
              transition={{ duration: 8, repeat: Infinity, ease: "linear" }}
            />
            <motion.div 
              className="absolute inset-0 rounded-full shadow-inner shadow-secondary"
              animate={{ rotate: -360 }}
              transition={{ duration: 8, repeat: Infinity, ease: "linear" }}
            />
            <ChatIcon className="relative z-10 transition-transform group-hover:scale-110" />
          </motion.button>

          {clearChat && (
            <Chat chatOpen={chatOpen} setChatOpen={handleChatOpen} />
          )}
        </div>
      </div>
    </div>
  );
}